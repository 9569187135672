export default {

  async fetchApiVersion({ commit }) {
    return await this.$axios.get("/app/version")
      .then((response) => {
        if (response.status === 200) {
          commit('setApiVersion', response.data);
        }
        return response;
      })
      .catch((error) => {
        console.log(error);
      });
  },

  // CONTRACTS

  async getContracts({ commit, dispatch }) {
    await this.$axios.get("/app/contracts")
      .then(res => {
        commit("setContracts", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getContractDetail({ commit, dispatch }, contractId) {
    await this.$axios.get("/app/contracts/" + contractId)
      .then(res => {
        commit("setContractDetail", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },



  async getContractSchedule({ commit, dispatch }, contractId) {
    let res = await this.$axios.get("/app/schedules/contract/" + contractId)
      
    if(res){
        return res.data;
    }
  },

  // ORDERS

  async getOrdersRate({ commit, dispatch }, payload = {}) {
    await this.$axios.get("/app/chemicals/rate")
      .then(res => {
        // console.log(res);
        commit("setOrdersRate", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getOrders({ commit, dispatch }, payload = {}) {
    let url = "/app/chemicals/orders";
    if (payload.contract_id !== undefined) {
      url = url + `?contract_id=${payload.contract_id}`;
    }
    await this.$axios.get(url)
      // await this.$axios.get('/temp_apis/order_list.json')
      .then(res => {
        // console.log(res);
        commit("setOrders", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getContractOrders({ commit, dispatch }, payload = {}) {
    // console.log("Get Contract Orders");
    let url = "/app/chemicals/orders";
    if (payload.contract_id !== undefined) {
      url = url + `?contract_id=${payload.contract_id}`;
    }
    await this.$axios.get(url)
      // await this.$axios.get('/temp_apis/order_list.json')
      .then(res => {
        // console.log("Get Contract Orders", res.data);
        commit("setContractOrders", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getOrderDetail({ commit, dispatch }, stockOrderId) {
    await this.$axios.get(`/app/chemicals/orders/${stockOrderId}`)
      // await this.$axios.get('/temp_apis/order_detail.json')
      .then(res => {
        commit("setOrderDetail", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getOrderTemplate({ commit, dispatch }, contractId) {
    await this.$axios.get(`/app/contracts/${contractId}/order_template`)
      // await this.$axios.get('/temp_apis/order_template.json')
      .then(res => {
        commit("setOrderTemplate", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  // EQUIPMENT

  async getEquipment({ commit, dispatch }) {
    await this.$axios.get("/app/equipment")
      // await this.$axios.get('/temp_apis/order_list.json')
      .then(res => {
        // console.log(res);
        commit("setEquipment", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
      commit("setAxiosLoading", false);
  },

  async getEquipmentDetail({ commit, dispatch }, equipmentId) {
    commit("setAxiosLoading", true);

    return await this.$axios.get(`/app/equipment/${equipmentId}`)
      // await this.$axios.get('/temp_apis/equipment_detail.json')
      .then(res => {
        commit("setAxiosLoading", false);

        commit("setEquipmentDetail", res.data);
        return res;
      })
      .catch(err => {
        console.log("ERROR STATUS", err.request.status);
        console.dir(err);
        console.error(err);
      });
  },

  async getEquipmentStatuses({ commit, dispatch }) {
    await this.$axios.get(`/app/equipment/status`)
      // await this.$axios.get('/temp_apis/equipment_detail.json')
      .then(res => {
        commit("setEquipmentStatuses", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getEquipmentLocationStatuses({ commit, dispatch }) {
    await this.$axios.get(`/app/equipment/location_status`)
      // await this.$axios.get('/temp_apis/equipment_detail.json')
      .then(res => {
        commit("setEquipmentLocationStatuses", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getEquipmentConditions({ commit, dispatch }) {
    await this.$axios.get(`/app/equipment/conditions`)
      // await this.$axios.get('/temp_apis/equipment_detail.json')
      .then(res => {
        commit("setEquipmentConditions", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  // SCHEDULE

  async reSchedule({ commit, dispatch }, payload) {
    // console.log("RESCHEDULE");
    await this.$axios
      .post("/app/reschedule", payload)
      .then(res => {
        alertify.success(`${payload.contract.company} has been rescheduled`);
        return res;
      })
      .catch(err => {
        alertify.error(
          `Error rescheduling... Please try again or report this issues`
        );
        console.error(err);
      });
  },

  // INSPECTIONS

  async getInspectionSchedule({ commit, dispatch }) {
    await this.$axios.get("/app/schedules")
      // await this.$axios.get('/temp_apis/contract_inspections.json')
      .then(res => {
        let orderedList = res.data.sort(
          (a, b) => new Date(a.scheduled_date) - new Date(b.scheduled_date)
        );

        commit("setInspectionSchedule", orderedList);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getMoreContractInspections({ commit, dispatch }, payload) {
    // console.log("Get More Inspections", payload);
    let url = `/app/contracts/${payload.contract_id}/more_inspections`;
    if (payload.from !== undefined) {
      let limit = payload.limit !== undefined ? payload.limit : 10;
      url = url + `?from=${payload.from}&limit=${limit}`;
    }

    await this.$axios.get(url)
      // await this.$axios.get('/temp_apis/more_inspections.json')
      .then(res => {
        commit("setMoreContractInspections", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getNewInspectionTemplate({ commit, dispatch }, contractId) {
    await this.$axios.get(`/app/contracts/${contractId}/new_inspection`)
      // await this.$axios.get('/temp_apis/new_inspection.json')
      .then(res => {
        // console.log(res);
        commit("setNewInspection", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async getInspectionDetail({ commit, dispatch }, payload) {
    await this.$axios.get(`/app/contracts/${payload.contract_id}/new_inspection?inspection_id=${payload.inspection_id}`)
      // this.$axios.get('/temp_apis/inspection_detail.json')
      .then(res => {
        commit("setInspectionDetail", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },
  async getOldInspectionDetails({ commit, dispatch }, payload) {
    await this.$axios.get(`/app/contracts/${payload.contract_id}/new_inspection?inspection_id=${payload.inspection_id}`)
      // this.$axios.get('/temp_apis/inspection_detail.json')
      .then(res => {
        commit("setPreviousInspectionDetail", res.data);
        return res;
      })
      .catch(err => {
        console.error(err);
      });
  },

  async forgotPassword({ commit, dispatch }, payload) {
    console.log("FORGOT PASSWORD");
    return await this.$axios.post("/app/auth/recovery", payload)
      .then(res => {
        alertify.success(`Check your email for an activation token.`);
        return true;
      })
      .catch(err => {
        alertify.error(
          `Error with forgot password... Please validate email and try again or contact support.`
        );
        console.error(err);

        return false;
      });
  },

  async uploadInspectionAreaImages({ commit, dispatch }, payload) {
    // console.log(payload.file);
    var formData = new FormData();
    formData.append("file", payload.file);
    formData.append("file_type_id", 2);
    formData.append("file_format_type_id", 1);
    return await this.$axios.post(`/app/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        // if (res.status === 200) {
        //   console.log(res.json());
        // }
        //console.log(res.data.id, 'image id');
        return res.data.id;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },

  async getInspectionImages({ commit, dispatch }, id) {
    return await this.$axios.get(`/app/files/${id}/view`, {
        responseType: "blob"
      })
      .then(res => {
        if (res.status === 200) {
          commit("setAreaImages", res.data);
          return res.data;
        }
      })
      .catch(err => {
        console.error(err);
      });
  },

  async uploadSignatureImage({ commit, dispatch }, payload) {
    //create form data
    // console.log(payload, "file");
    var formData = new FormData();
    formData.append("file_encoded", payload.data);
    formData.append("file_type_id", payload.type_id);
    formData.append("file_format_type_id", 2);

    return await this.$axios.post(`/app/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        // if (res.status === 200) {
        //   console.log(res.json());
        // }
        //console.log(res.data.id, 'image id');
        return res.data.id;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },

  async resetPassword({ commit, dispatch }, payload) {
    return await this.$axios.post("/app/auth/reset", payload)
      .then(res => {
        alertify.success(`Success! Please log in with your new password`);
        return true;
      })
      .catch(err => {
        alertify.error(
          `Error with forgot password... Please validate email and try again or contact support.`
        );
        console.error(err);
        return false;
      });
  },

  async addCompletedInspection({ commit, dispatch }, payload) {
    // console.log("add inspoection");
    await this.$axios.post("/app/inspections", payload)
      .then(res => {
        alertify.success(`Success! Inspection successfully created`);
        // console.log(res, "response");
        return res;
      })
      .catch(err => {
        alertify.error(
          `Error with creating inspection, Please try again or contact support.`
        );
        console.error(err);
      });
  },

  async verifyEquipment({ commit, dispatch }, payload) {
    commit("setAxiosLoading", true);

    // console.log(payload);
    var formData = new FormData();
    formData.append("photo", payload.file);
    return await this.$axios.post(`/app/equipment/${payload.id}/verify`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        commit("setAxiosLoading", false);

        commit("setEquipmentDetail", res.data);

        return res.data;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },

  async fetchEquipmentImages({ commit, dispatch }, id) {
    return await this.$axios.get(`/app/equipment/image/${id}`, {
        responseType: "blob"
      })
      .then(res => {
        if (res.status === 200) {
          // console.log(res.data);
          return res.data;
        }
      })
      .catch(err => {
        console.error(err);
      });
  },

  async updateEquipment({ commit, dispatch }, payload) {
    // console.log(payload);
    return await this.$axios.post(`/app/equipment/${payload.id}`, payload.data)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },
  async updateOrder({ commit, dispatch }, payload) {
    // console.log(payload);
    return await this.$axios.put(`/app/chemicals/orders/${payload.id}`, payload.data)
      .then(res => {
        return res.status;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },
  async createNewOrder({ commit, dispatch }, payload) {
    // console.log(payload);
    commit("setAxiosLoading", true);
    return await this.$axios.post(`/app/chemicals/orders/`, payload)
      .then(res => {
        commit("setAxiosLoading", false);
        return res.status;
      })
      .catch(err => {
        console.error(err, "error");
      });
  },

  // CLEANING - CHECKLIST
  async logInWithPin({ commit, dispatch }, payload){
    return await this.$axios.get(`/app/cleaning/logs?contract_id=${payload.id}&pin=${payload.pin}`).then(res => {
      commit("setAllCleaningLogs", res.data)
      return res
    })
    .catch(err => {
      return err
      console.error(err);
    });
  },
  async forgotPin({ commit, dispatch }, payload){
    return await this.$axios.get(`/app/cleaning/logs/forgotpin?contract_id=${payload}`).then(res => {
      return res
    })
    .catch(err => {
      console.error(err);
    });
  },
  async roomLogin({ commit, dispatch }, payload){
    return await this.$axios.post(`/app/cleaning/checklists`, {checklist_code: payload}).then(res => {
      if(res && res.status == 200){
        commit("setCleaningRoom", res.data) 
      }
      return res.status
    })
    .catch(err => {
      console.error(err);
      return err
    });
  },
  async startChecklistSession({ commit, dispatch }, payload){
    return await this.$axios.post(`/app/cleaning/checklists/${payload.checklist_id}`, {employee_code: payload.employee_code}).then(res => {
      commit("setStartedChecklist", res.data)
      return res
    })
    .catch(err => {
      console.error(err);
    });
  },
  async completeChecklistSession({ commit, dispatch }, payload){
    return await this.$axios.put(`/app/cleaning/checklists/${payload.checklist_id}/sessions/${payload.session_id}`, {employee_code: payload.employee_code, tasks_answered: payload.tasks_answered, note: payload.note}).then(res => {
      return res
    })
    .catch(err => {
      console.error(err);
    });
  },
  async uploadPhotoChecklist({ commit, dispatch }, payload){
    var formData = new FormData();
    formData.append("photo", payload.photo);
    formData.append("employee_code", payload.employee_code);
    
    return await this.$axios.post(`/app/cleaning/checklists/${payload.checklist_id}/sessions/${payload.session_id}/photos`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(res => {
        return res
      })
      .catch(err => {
        console.error(err, "error");
      });

  },
  async deletePhotoChecklist({ commit, dispatch }, payload){
    return await this.$axios.delete(`/app/cleaning/checklists/${payload.id}/sessions/${payload.session_id}/photos/${payload.photo}?employee_code=${payload.employee_code}`, {employee_code: payload.employee_code}).then(res => {
      return res
    })
    .catch(err => {
      console.error(err);
    });
  },


  // =======================================  EMPLOYEES  =============================================================

  
  async fetchInitEmployeeData({ commit, dispatch }){
    try {
      let banks = await this.$axios.get(`/employee_app/banks`)
      let doc_types = await this.$axios.get(`/employee_app/employee_document_types`)
      console.log('fetchInitEmployeeData','banks', banks.data)

      commit("setBanks", banks.data)
      commit("setDocTypes", doc_types.data)
    } catch (err) {
      console.error(err)
      return err
    }
  },


  // SCAN LOGIN
  async verifyEmployee({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/auth/login`, payload)
      console.log('verifyEmployee',response)
      return response
    } catch (err) {
      alertify.error('Error verifying the employee login.<br/> <b>'+ err.response.data.message+'</b>')
      console.error(err)
      return err
    }
  },

  // LOGIN PIN SMS
  async requestEmployeeOTP({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/auth/reset-pin-request`, payload)
      console.log('requestEmployeeOTP',response)
      return response
    } catch (err) {
      alertify.error('Error sending OTP.<br/> <b>'+ err.response.data.message+'</b>')
      console.error(err)
      return err
    }
  },


  // LOGIN PIN SMS
  async updateEmployeePIN({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/auth/reset-pin`, payload)
      console.log('updateEmployeePIN',response)
      return response
    } catch (err) {
      alertify.error('Error resetting reset PIN.<br/> <b>'+ err.response.data.message+'</b>')
      console.error(err)
      return err
    }
  },

  //  REQUESTS 


  async fetchRequestTypes({ commit, dispatch }){
    try {
      let response = await this.$axios.get(`/employee_app/request_types`)
      console.log('fetchRequestTypes',response)
      commit("setRequestTypes", response.data)
    } catch (err) {
      console.error(err)
      return err
    }
  },

  async fetchRequests({ commit, dispatch }){
    try {
      let response = await this.$axios.get(`/employee_app/requests`)
      commit("setRequests", response.data)
    } catch (err) {
      console.error(err)
      return err
    }
  },


  async createRequest({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/requests`,payload)
      console.log('createRequest',response, payload)
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  },
  
  async updateRequest({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.patch(`/app/request/${payload.id}/status`,payload)
      console.log('updateRequest',response, payload)
      return true
    } catch (err) {
      console.error(err)
      return err
    }
  },

  async updateRequestStatus({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.patch(`/employee_app/request/${payload.id}/status`,payload)
      console.log('updateRequest',response, payload)
      return true
    } catch (err) {
      console.error(err)
      return err
    }
  },

  async fetchRequestMedia({ commit, dispatch }, payload){
    // TODO: Hook up API
    try {
      let response = await this.$axios.get(`/employee_app/requests/${payload.request_id}/media/${payload.media_id}`)
      console.log('fetchRequestStatuses',response)
      // TODO: commit("setRequestTypes", response.data)
    } catch (err) {
      console.error(err)
      return err
    }
  },  

  
  async fetchStockItems({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.get(`/employee_app/stock_items`)
      console.log('fetchRequestStatuses',response)
      commit("setStockItems", response.data)
    } catch (err) {
      console.error(err)
      return err
    }
  },  

  
  async fetchContractFromQR({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/room_lookup`,{
        "qr_code": payload
      })
      console.log('fetchContractFromQR',response)
      return response.data
    } catch (err) {
      console.error(err)
      return err
    }
  },  

  async employeeCheckIn({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/check_in`, payload)
      if(response){
        await this.$auth.fetchUser()
        console.log('employeeCheckIn',response)
        return true
      }
    } catch (err) {
      console.error(err)
      // alertify.error('Error checking in.<br/> <b>'+ err.response.data.message+'</b>')
      return false
    }
  },    



  
  async employeeCheckOut({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.post(`/employee_app/check_out`, payload)
      if(response){
        await this.$auth.fetchUser()
        console.log('employeeCheckOut',response)
        return true
      }
      return false
    } catch (err) {
      console.error(err)
      return false
    }
  },    


  async fetchEmployeeTimesheet({ commit, dispatch }, payload){
    try {
      let response = await this.$axios.get(`/employee_app/timesheet?start_date=`+payload)
      if(response){
        console.log('fetchEmployeeTimesheet',response)
        commit("setTimeSheets", response.data)
          return true
      }
    } catch (err) {
      console.error(err)
      return err
    }
  },   

};
