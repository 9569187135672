import { defaultState } from './defaultState.js';
import Vue from 'vue'
export default {
  resetState (state) {
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    let resetState = defaultState();
    delete resetState.auth;
    Object.assign(state, resetState)
  },

  setApiVersion (state, data) {
    state.apiVersion = data.version;
  },

  setAppMode(state, payload){
    state.mode = payload
  },

  // CONTRACTS
  setContracts(state, payload){
    state.contracts = payload.sort((a,b) => a.company.localeCompare(b.company));
  },

  setContractDetail(state, payload){
    state.contractDetail = payload

    var inspectionDate = new Date(payload.inspections.scheduled_inspection.date_start)
    if((inspectionDate.getDate() == new Date().getDate()) && (inspectionDate.getMonth() == new Date().getMonth())){
      state.contractDetail.inspections.isToday = true
    }

    // Structure the equipment List by Status
    if(payload.equipment && payload.equipment.length > 0){


      state.contractDetail.equipmentSorted = {}


      state.contractDetail.equipmentSorted.collection = payload.equipment.filter(equipmentItem => equipmentItem.location_status_id == 0).sort((a,b) => {
        if(a.next_verification && b.next_verification){
          return a.next_verification.localeCompare(b.next_verification)
        }
        
        return false
      })
      state.contractDetail.equipmentSorted.contract = payload.equipment.filter(equipmentItem => equipmentItem.location_status_id == 2).sort((a,b) => {
        if(a.next_verification && b.next_verification){
          return a.next_verification.localeCompare(b.next_verification)
        }
        
        return false
      })
      state.contractDetail.equipmentSorted.delivery = payload.equipment.filter(equipmentItem => equipmentItem.location_status_id == 1).sort((a,b) => {
        if(a.next_verification && b.next_verification){
          return a.next_verification.localeCompare(b.next_verification)
        }
        
        return false
      })
      state.contractDetail.equipmentSorted.stores = payload.equipment.filter(equipmentItem => equipmentItem.location_status_id == 3).sort((a,b) => {
        if(a.next_verification && b.next_verification){
          return a.next_verification.localeCompare(b.next_verification)
        }
        
        return false
      })
    }

  },

  removeContractDetail(state){
    state.contractDetail = {}
  },


  // ORDERS

  setOrdersRate(state, payload) {
    state.ordersRate = payload;
  },

  setOrders(state, payload){
    state.orders = payload
  },

  setContractOrders(state, payload){
    console.log('Set Contract Orders',payload)
    state.contractDetailOrders = payload
  },

  setOrderDetail(state, payload){
    state.orderDetail = payload
  },
  destroyOrderDetail(state) {
    state.orderDetail = {}
  },

  setOrderTemplate(state, template) {
    state.orderTemplate = template;
  },

  // EQUIPMENT

  setEquipment(state, payload){
    state.equipment = payload
  },

  setEquipmentDetail(state, payload){
    state.equipmentDetail = payload
  },

  setEquipmentStatuses(state, payload){
    state.equipment_statuses = payload
  },

  setEquipmentLocationStatuses(state, payload){
    state.equipment_location_statuses = payload
  },

  setEquipmentConditions(state, payload){
    state.equipment_conditions = payload
  },

  // INSPECTIONS

  setInspectionSchedule(state, payload){
    if(payload && payload.length > 0){
      // var previousDayTester = '';
      state.inspections.list = payload;
      state.inspections.sorted = [];

      // payload.forEach(inspectionItem => {
      //   // Check if Date is different from Previous - assuming list is returned ordered
      //   var currentDate = new Date(inspectionItem.scheduled);
      //   var testDate = currentDate.getDate() + '-' + currentDate.getMonth()
      //   if (previousDayTester != testDate) {
      //     var inspectionList = state.inspections.list.filter((inspectionArrItem) => {
      //       var testArrDate = new Date(inspectionArrItem.scheduled);
      //       return currentDate.valueOf() == testArrDate.valueOf();
      //     });

      //     state.inspections.sorted.push(
      //       {
      //         date:{
      //           full:currentDate,
      //           day:currentDate.getDate(),
      //         },
      //         inspections:inspectionList
      //       }
      //     );
      //     previousDayTester == testDate;
      //   }
      // });
    }

    // state.inspections = payload
  },

  setMoreContractInspections(state, payload){
    console.log('Set More Inspections', payload)
    state.contractDetail.inspections.inspections_history.push(...payload)
  },

  setNewInspection(state, payload){
    state.newInspectionTemplate = payload
  },

  setInspectionDetail(state, payload){
    state.inspectionDetail = payload
  },
  setPreviousInspectionDetail(state, payload){
    state.previousInspectionDetail = payload
  },
  setPreviousInsppectionId(state, payload){
    state.previousInspectionId = payload
  },

  setAreaImages(state, payload){
    if (payload != null) {
    state.areaImages.push(payload)
    } else {
      state.areaImages = []
    }
  },

  setCleaningNote(state, payload){
    state.cleaning.checklist.note = payload
  },


  // ACTION PAGES

  loadActionPage(state, payload) {
    console.log('OPEN ACTION PAGE',payload)
    state.ui.activeModel = payload.pageID;
    if(payload.pageData != ''){
      state.ui.activeModelData = payload.pageData;
    }else{
      state.ui.activeModelData = null;
    }
  },
  closeActionPage(state) {

  },
  setUser(state, payload){
    state.user = {...state.user, ...payload}
  },

  setAxiosLoading(state, loading = null) {
    state.axios.loading = loading;
  },

  setAxiosError(state, error = null) {
    state.axios.error = error;
  },

  setAlertNotification(state, alertNotification = null) {
    state.alertNotification = alertNotification;
  },
  setExpiredDate(state, expiresIn = 3600) {
    var dt = new Date();
    dt.setSeconds( dt.getSeconds() + expiresIn)
    window.localStorage.setItem('auth._expired_date', dt);
    state.auth.expired = dt;
  },


  // CLEANING - CHECKLISTS
  setCleaningRoom(state, payload){
    state.cleaning.room = payload
  },
  setStartedChecklist(state, payload){
    state.cleaning.checklist = payload
    state.cleaning.checklist.tasks_answered = {}
    state.cleaning.room.tasks.forEach(task => {
      state.cleaning.checklist.tasks_answered[task] = true
    });
  },
  setChecklist(state, payload){
    state.cleaning.checklist = payload
  },
  setTaskAnswered(state, payload){
    let copyTask = JSON.parse(JSON.stringify(state.cleaning.checklist.tasks_answered))
    copyTask[payload.task] = payload.bool
    $nuxt.$set(state.cleaning.checklist, "tasks_answered",  copyTask)
  },
  setChecklistPhotos(state, payload){
    $nuxt.$set(state.cleaning.checklist, "photos",  payload)
  },
  setAllCleaningLogs(state, payload){
    $nuxt.$set(state.cleaning, "logs",  payload)
  },


  // EMPLOYEES

  setBanks(state, payload){
    state.ui.bankList = payload
  },
  
  setDocTypes(state, payload){
    state.ui.docTypes = payload
  },
  

  //REQUESTS

  setRequestTypes(state, payload){
    state.request_types = payload
  },

  setRequests(state, payload){
    state.requests = payload
  },
  
  setStockItems(state, payload){
    state.stockItems = payload
  },

  setTimeSheets(state, payload){
    state.timesheets = payload
  },

}
