import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _561ff044 = () => interopDefault(import('../pages/cleaning/index.vue' /* webpackChunkName: "pages/cleaning/index" */))
const _4f1693e8 = () => interopDefault(import('../pages/contracts/index.vue' /* webpackChunkName: "pages/contracts/index" */))
const _b285748e = () => interopDefault(import('../pages/equipment/index.vue' /* webpackChunkName: "pages/equipment/index" */))
const _7750246a = () => interopDefault(import('../pages/inspections/index.vue' /* webpackChunkName: "pages/inspections/index" */))
const _8b0e7c58 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _506f3e92 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _1239a730 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _407b771b = () => interopDefault(import('../pages/sink.vue' /* webpackChunkName: "pages/sink" */))
const _3b456870 = () => interopDefault(import('../pages/cleaning/logs/index.vue' /* webpackChunkName: "pages/cleaning/logs/index" */))
const _f484e058 = () => interopDefault(import('../pages/login/forgot/index.vue' /* webpackChunkName: "pages/login/forgot/index" */))
const _50cbe82e = () => interopDefault(import('../pages/login/scan/index.vue' /* webpackChunkName: "pages/login/scan/index" */))
const _00c9a133 = () => interopDefault(import('../pages/cleaning/logs/pin.vue' /* webpackChunkName: "pages/cleaning/logs/pin" */))
const _6630917d = () => interopDefault(import('../pages/cleaning/scan/complete.vue' /* webpackChunkName: "pages/cleaning/scan/complete" */))
const _1114361f = () => interopDefault(import('../pages/cleaning/scan/room.vue' /* webpackChunkName: "pages/cleaning/scan/room" */))
const _3038df3c = () => interopDefault(import('../pages/employees/check-in/room-scan.vue' /* webpackChunkName: "pages/employees/check-in/room-scan" */))
const _2e7ffdd1 = () => interopDefault(import('../pages/login/forgot/reset.vue' /* webpackChunkName: "pages/login/forgot/reset" */))
const _8d6fdea6 = () => interopDefault(import('../pages/login/scan/employee/_employee_id/index.vue' /* webpackChunkName: "pages/login/scan/employee/_employee_id/index" */))
const _70e3a8c0 = () => interopDefault(import('../pages/login/scan/employee/_employee_id/expired.vue' /* webpackChunkName: "pages/login/scan/employee/_employee_id/expired" */))
const _620a7eaa = () => interopDefault(import('../pages/login/scan/employee/_employee_id/reset.vue' /* webpackChunkName: "pages/login/scan/employee/_employee_id/reset" */))
const _63adb6b9 = () => interopDefault(import('../pages/cleaning/checklist/_qrcode/index.vue' /* webpackChunkName: "pages/cleaning/checklist/_qrcode/index" */))
const _b25b186c = () => interopDefault(import('../pages/orders/new/_contract_id.vue' /* webpackChunkName: "pages/orders/new/_contract_id" */))
const _42c3d4be = () => interopDefault(import('../pages/cleaning/checklist/_id/view.vue' /* webpackChunkName: "pages/cleaning/checklist/_id/view" */))
const _60c9a5ce = () => interopDefault(import('../pages/cleaning/logs/_qrcode/view.vue' /* webpackChunkName: "pages/cleaning/logs/_qrcode/view" */))
const _49bc4a94 = () => interopDefault(import('../pages/cleaning/scan/_qrcode/employee.vue' /* webpackChunkName: "pages/cleaning/scan/_qrcode/employee" */))
const _0aa82a12 = () => interopDefault(import('../pages/contracts/_id/index.vue' /* webpackChunkName: "pages/contracts/_id/index" */))
const _c2f7c78c = () => interopDefault(import('../pages/contracts/_id/index/index.vue' /* webpackChunkName: "pages/contracts/_id/index/index" */))
const _88ca9a14 = () => interopDefault(import('../pages/contracts/_id/index/equipment.vue' /* webpackChunkName: "pages/contracts/_id/index/equipment" */))
const _76bada67 = () => interopDefault(import('../pages/contracts/_id/index/inspections.vue' /* webpackChunkName: "pages/contracts/_id/index/inspections" */))
const _36385b2d = () => interopDefault(import('../pages/contracts/_id/index/orders.vue' /* webpackChunkName: "pages/contracts/_id/index/orders" */))
const _ba828b02 = () => interopDefault(import('../pages/e/_employee_id.vue' /* webpackChunkName: "pages/e/_employee_id" */))
const _4d9adc24 = () => interopDefault(import('../pages/equipment/_id/index.vue' /* webpackChunkName: "pages/equipment/_id/index" */))
const _71660b56 = () => interopDefault(import('../pages/inspections/_id/index.vue' /* webpackChunkName: "pages/inspections/_id/index" */))
const _c53094d0 = () => interopDefault(import('../pages/orders/_id.vue' /* webpackChunkName: "pages/orders/_id" */))
const _73a6ef1c = () => interopDefault(import('../pages/employees/_employee_id/check-out/index.vue' /* webpackChunkName: "pages/employees/_employee_id/check-out/index" */))
const _428919bc = () => interopDefault(import('../pages/employees/_employee_id/profile/index.vue' /* webpackChunkName: "pages/employees/_employee_id/profile/index" */))
const _3f1b3982 = () => interopDefault(import('../pages/employees/_employee_id/profile/index/index.vue' /* webpackChunkName: "pages/employees/_employee_id/profile/index/index" */))
const _273ab7a5 = () => interopDefault(import('../pages/employees/_employee_id/profile/index/documents.vue' /* webpackChunkName: "pages/employees/_employee_id/profile/index/documents" */))
const _7d3c1e14 = () => interopDefault(import('../pages/employees/_employee_id/profile/index/financial.vue' /* webpackChunkName: "pages/employees/_employee_id/profile/index/financial" */))
const _03d480a7 = () => interopDefault(import('../pages/employees/_employee_id/requests/index.vue' /* webpackChunkName: "pages/employees/_employee_id/requests/index" */))
const _a4992946 = () => interopDefault(import('../pages/employees/_employee_id/scan.vue' /* webpackChunkName: "pages/employees/_employee_id/scan" */))
const _09fabaa4 = () => interopDefault(import('../pages/employees/_employee_id/timesheets/index.vue' /* webpackChunkName: "pages/employees/_employee_id/timesheets/index" */))
const _068de4ec = () => interopDefault(import('../pages/equipment/_id/repaired.vue' /* webpackChunkName: "pages/equipment/_id/repaired" */))
const _a47388f4 = () => interopDefault(import('../pages/inspections/_id/complete.vue' /* webpackChunkName: "pages/inspections/_id/complete" */))
const _4d90def9 = () => interopDefault(import('../pages/employees/_employee_id/check-out/room-scan.vue' /* webpackChunkName: "pages/employees/_employee_id/check-out/room-scan" */))
const _2bd05c53 = () => interopDefault(import('../pages/employees/_employee_id/requests/sent.vue' /* webpackChunkName: "pages/employees/_employee_id/requests/sent" */))
const _d1bc75fe = () => interopDefault(import('../pages/employees/_employee_id/timesheets/wage-query.vue' /* webpackChunkName: "pages/employees/_employee_id/timesheets/wage-query" */))
const _309c838a = () => interopDefault(import('../pages/contracts/_id/requests/_request_id.vue' /* webpackChunkName: "pages/contracts/_id/requests/_request_id" */))
const _4fa9ff02 = () => interopDefault(import('../pages/employees/_employee_id/requests/_request_id.vue' /* webpackChunkName: "pages/employees/_employee_id/requests/_request_id" */))
const _1738ae0c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/cleaning",
      component: _561ff044,
      name: "cleaning"
    }, {
      path: "/contracts",
      component: _4f1693e8,
      name: "contracts"
    }, {
      path: "/equipment",
      component: _b285748e,
      name: "equipment"
    }, {
      path: "/inspections",
      component: _7750246a,
      name: "inspections"
    }, {
      path: "/login",
      component: _8b0e7c58,
      name: "login"
    }, {
      path: "/logout",
      component: _506f3e92,
      name: "logout"
    }, {
      path: "/orders",
      component: _1239a730,
      name: "orders"
    }, {
      path: "/sink",
      component: _407b771b,
      name: "sink"
    }, {
      path: "/cleaning/logs",
      component: _3b456870,
      name: "cleaning-logs"
    }, {
      path: "/login/forgot",
      component: _f484e058,
      name: "login-forgot"
    }, {
      path: "/login/scan",
      component: _50cbe82e,
      name: "login-scan"
    }, {
      path: "/cleaning/logs/pin",
      component: _00c9a133,
      name: "cleaning-logs-pin"
    }, {
      path: "/cleaning/scan/complete",
      component: _6630917d,
      name: "cleaning-scan-complete"
    }, {
      path: "/cleaning/scan/room",
      component: _1114361f,
      name: "cleaning-scan-room"
    }, {
      path: "/employees/check-in/room-scan",
      component: _3038df3c,
      name: "employees-check-in-room-scan"
    }, {
      path: "/login/forgot/reset",
      component: _2e7ffdd1,
      name: "login-forgot-reset"
    }, {
      path: "/login/scan/employee/:employee_id?",
      component: _8d6fdea6,
      name: "login-scan-employee-employee_id"
    }, {
      path: "/login/scan/employee/:employee_id?/expired",
      component: _70e3a8c0,
      name: "login-scan-employee-employee_id-expired"
    }, {
      path: "/login/scan/employee/:employee_id?/reset",
      component: _620a7eaa,
      name: "login-scan-employee-employee_id-reset"
    }, {
      path: "/cleaning/checklist/:qrcode?",
      component: _63adb6b9,
      name: "cleaning-checklist-qrcode"
    }, {
      path: "/orders/new/:contract_id?",
      component: _b25b186c,
      name: "orders-new-contract_id"
    }, {
      path: "/cleaning/checklist/:id?/view",
      component: _42c3d4be,
      name: "cleaning-checklist-id-view"
    }, {
      path: "/cleaning/logs/:qrcode/view",
      component: _60c9a5ce,
      name: "cleaning-logs-qrcode-view"
    }, {
      path: "/cleaning/scan/:qrcode?/employee",
      component: _49bc4a94,
      name: "cleaning-scan-qrcode-employee"
    }, {
      path: "/contracts/:id",
      component: _0aa82a12,
      children: [{
        path: "",
        component: _c2f7c78c,
        name: "contracts-id-index"
      }, {
        path: "equipment",
        component: _88ca9a14,
        name: "contracts-id-index-equipment"
      }, {
        path: "inspections",
        component: _76bada67,
        name: "contracts-id-index-inspections"
      }, {
        path: "orders",
        component: _36385b2d,
        name: "contracts-id-index-orders"
      }]
    }, {
      path: "/e/:employee_id?",
      component: _ba828b02,
      name: "e-employee_id"
    }, {
      path: "/equipment/:id",
      component: _4d9adc24,
      name: "equipment-id"
    }, {
      path: "/inspections/:id",
      component: _71660b56,
      name: "inspections-id"
    }, {
      path: "/orders/:id",
      component: _c53094d0,
      name: "orders-id"
    }, {
      path: "/employees/:employee_id?/check-out",
      component: _73a6ef1c,
      name: "employees-employee_id-check-out"
    }, {
      path: "/employees/:employee_id?/profile",
      component: _428919bc,
      children: [{
        path: "",
        component: _3f1b3982,
        name: "employees-employee_id-profile-index"
      }, {
        path: "documents",
        component: _273ab7a5,
        name: "employees-employee_id-profile-index-documents"
      }, {
        path: "financial",
        component: _7d3c1e14,
        name: "employees-employee_id-profile-index-financial"
      }]
    }, {
      path: "/employees/:employee_id?/requests",
      component: _03d480a7,
      name: "employees-employee_id-requests"
    }, {
      path: "/employees/:employee_id?/scan",
      component: _a4992946,
      name: "employees-employee_id-scan"
    }, {
      path: "/employees/:employee_id?/timesheets",
      component: _09fabaa4,
      name: "employees-employee_id-timesheets"
    }, {
      path: "/equipment/:id/repaired",
      component: _068de4ec,
      name: "equipment-id-repaired"
    }, {
      path: "/inspections/:id/complete",
      component: _a47388f4,
      name: "inspections-id-complete"
    }, {
      path: "/employees/:employee_id?/check-out/room-scan",
      component: _4d90def9,
      name: "employees-employee_id-check-out-room-scan"
    }, {
      path: "/employees/:employee_id?/requests/sent",
      component: _2bd05c53,
      name: "employees-employee_id-requests-sent"
    }, {
      path: "/employees/:employee_id?/timesheets/wage-query",
      component: _d1bc75fe,
      name: "employees-employee_id-timesheets-wage-query"
    }, {
      path: "/contracts/:id/requests/:request_id?",
      component: _309c838a,
      name: "contracts-id-requests-request_id"
    }, {
      path: "/employees/:employee_id?/requests/:request_id",
      component: _4fa9ff02,
      name: "employees-employee_id-requests-request_id"
    }, {
      path: "/",
      component: _1738ae0c,
      name: "index"
    }],

    fallback: false
  })
}
